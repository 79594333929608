// export const baseUrl = "https://node.gyanbatua.com/api/v1";
// export const baseUrl = "https://nodev3-learningrecords.mobiloitte.io/api/v1";
const baseUrl = process.env.REACT_APP_BASE_URL;

const user = `${baseUrl}/user`;
const course = `${baseUrl}/course`;
const watchlist = `${baseUrl}/watchlist`;
const skill = `${baseUrl}/skill`;
const certificate = `${baseUrl}/certificate`;
const notification = `${baseUrl}/notification`;
const ticket = `${baseUrl}/ticket`;
const cart = `${baseUrl}/cart`;
const payment = `${baseUrl}/payment`;
const price = `${baseUrl}/price`;
const coe = `${baseUrl}/coePartners`;
const media = `${baseUrl}/media`;
const Blogs = `${baseUrl}/blog`;
const Gst = `${baseUrl}/gst`;
const referalcode = `${baseUrl}/referalcode`;
const seo = `${baseUrl}/courseseo`;
const Categoryseo = `${baseUrl}/categoryseo`;
const SubCategoryseo = `${baseUrl}/subcategoryseo`;
const baseUrl1 = baseUrl.split("/api/v1")[0] + "/";
const Membership = `${baseUrl}/membership`;

export const apiConfig = {

  getGCBalance :`${payment}/getGCBalance`,
  getLinkedInAccessToken: `${user}/getLinkedInAccessToken`,
  getUserInfoLinkedin: `${user}/linkedinLoginUserDetails`,
  url: `${baseUrl1}login`,
  blogList: `${Blogs}/blogList`,
  viewBlog: `${Blogs}/viewBlog`,
  addGst: `${Gst}/addGst`,
  getProfileResume: `${user}/getProfileResume`,
  deleteProfileResume: `${user}/deleteProfileResume`,
  listMedia: `${media}/listMedia`,
  viewMedia: `${media}/viewMedia`,
  CoePartnerList: `${coe}/CoePartnerList`,
  viewCoePartner: `${coe}/viewCoePartner`,
  priceDataList: `${price}/priceDataList`,
  removeWatchlist: `${watchlist}/removeWatchlist`,
  register: `${user}/register`,
  signUpBounty: `${user}/signUpBounty`,
  courseBounty: `${user}/courseBounty`,
  login: `${user}/login`,
  newGyanis: `${user}/newGyanis`,
  globalSearchApi: `${user}/globalSearchApi`,
  forgotPassword: `${user}/forgotPassword`,
  verify_email_otp: `${user}/verify_email_otp`,
  resend_otp_email: `${user}/resend_otp_email`,
  resetPassword: `${user}/resetPassword`,
  listTrendingCourse: `${course}/listTrendingCourse`,
  uploadIpfs: `${course}/uploadIpfs`,
  getUserProfile: `${user}/profile`,
  uploadIpfsImage: `${user}/uploadIpfsImage`,
  getSubCategoryById:`${course}/getSubCategoryById`,
  updateUserProfile: `${user}/updateProfile`,
  searchUserWithSkills: `${user}/searchUserWithSkills`,
  getAllCategory: `${course}/getAllCategory`,
  transactionHBD: `${user}/transactionHBD`,
  createResumeUsingAi: `${user}/createResumeUsingAi`,
  listallResume: `${user}/listallResume`,
  getResume: `${user}/getResume`,
  deleteResume: `${user}/deleteResume`,
  getAllSubCategory: `${course}/getAllSubCategory`,
  getDetails: `${user}/getDetails`,
  checkWatchlistStatus: `${user}/checkWatchlistStatus`,
  filterCourse: `${course}/filterCourse`,
  getTrendingSubCategory: `${course}/getTrendingSubCategory`,
  getSpotlightSubCategory: `${course}/getSpotlightSubCategory`,
  subscribeEmail: `${user}/subscribe`,
  userBalance: `${user}/userBalance`,
  listWatchList: `${watchlist}/listWatchList`,
  listOwnWatchList: `${watchlist}/listOwnWatchList`,
  updateStatusWatchlist: `${watchlist}/updateStatusWatchlist`,
  transaction: `${user}/transaction`,
  skillList: `${skill}/skillsList`,
  subSkillList: `${skill}/subSkillsList`,
  uploadImage: `${user}/uploadImage`,
  addCertificate: `${certificate}/addCertificate`,
  fetchUserSkillsSubSkills:`${certificate}/fetchUserSkillsSubSkills`,
  certificateStatus: `${certificate}/certificateStatus`,
  socialLogin: `${user}/socialLogin`,
  getUserBadge: `${user}/getUserBadge`,
  certificateList: `${certificate}/certificateList`,
  approveRejectCertificated: `${certificate}/approveRejectCertificate`,
  getAllCoursesBySubCategory: `${course}/getAllCoursesBySubCategory`,
  changePassword: `${user}/changePassword`,
  listCourse: `${course}/listCourse`,
  getCourse: `${course}/getCourse`,
  createCourse: `${course}/createCourse`,
  listNotification: `${notification}/listNotification`,
  viewNotification:`${notification}/viewNotification`,
  deleteNotification: `${notification}/deleteNotification`,
  clearNotification: `${notification}/clearNotification`,
  updateIsReadStatus:`${notification}/updateIsReadStatus`,
  addResume: `${user}/addResume`,
  sendMobileOtp: `${user}/resend_otp_mobile`,
  mobileOtpVerification: `${user}/mobileOtpVerification`,
  addProfilePic: `${user}/addProfilePic`,
  ticketEnquiryList: `${ticket}/ticketEnquiryList`,
  addTicketEnquiry: `${ticket}/addTicketEnquiry`,
  viewTicketEnquiry: `${ticket}/viewTicketEnquiry`,
  deleteTicketEnquiry: `${ticket}/deleteTicketEnquiry`,
  multipleTicketDelete: `${ticket}/multipleTicketDelete`,
  
  addToCart: `${cart}/addToCart`,
  addBundleToCart: `${cart}/addBundleToCart`,
  viewCart: `${cart}/viewCart`,
  removeFromCart: `${cart}/removeItemFromCart`,
  getAllDropCourse: `${user}/getAllDropCourse`,
  getDetails: `${user}/getDetails`,
  transactionHBD: `${user}/transactionHBD`,
  myCourse: `${course}/myCourse`,
  scanCode: `${user}/scanCode`,
  recomendCourse: `${course}/recomendCourse`,
  incrementView: `${course}/incrementView`,
  listSkillBridgeCourse: `${course}/listSkillBridgeCourse`,
  recomendCourseForOther: `${course}/recomendCourseForOther`,
  viewTemplate: `${course}/viewTemplate`,
  partnerForm: `${baseUrl}/landingUniversity/addLandingUniversity`,
  checkEmailExists: `${user}/checkEmailExist`,
  createCourseEducator: `${course}/createCourseEducator`,
  //payment
  checkOut: `${payment}/checkOut`,
  paymentVerification: `${payment}/paymentVerification`,
  getTransactionId: `${payment}/getTransaction`,
  checkOutCart: `${payment}/checkOutCart`,
  checkOutBundleInCart: `${payment}/checkOutBundleInCart`,
  bundlePaymentVerify: `${payment}/bundlePaymentVerify`,
  //buy subscription
  buySubscription: `${payment}/buySubscription`,
  subcriptionPaymentVerification: `${payment}/subcriptionPaymentVerification`,
  cancelSubscription: `${payment}/cancelSubscription`,
  // membership
  listPlan: `${Membership}/listPlan`,
  // add watchlist
  addWatchlist: `${watchlist}/addWatchList`,
  viewRefralCode: `${referalcode}/viewReferalCode`,
  checkReferalCode: `${referalcode}/checkReferalCode`,
  viewCourseSEO: `${seo}/viewCourseSEO`,
  viewCategorySEO: `${Categoryseo}/viewCategorySEO`,
  viewSubCategorySEO: `${SubCategoryseo}/viewSubCategorySEO`,
  findJobsUsingAi: `${user}/findJobsUsingAi`,
  findAdditionalSkillsUsingAi: `${user}/findAdditionalSkillsUsingAi`,
  usersSavedJobs: `${user}/usersSavedJobs`,
  userSaveUnSaveJobOpportunity: `${user}/userSaveUnSaveJobOpportunity`,
};
